import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import Introduction from '../components/common/sections/introduction';
import ItemGrid from '../components/common/sections/itemGrid';

const EventsPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;
  const eventData = data.eventData.edges;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <Introduction contentSection={contentSections[0]} />
      <ItemGrid isLast itemData={eventData} />
    </>
  );
};

export const query = graphql`
  query {
    eventData: allFile(
      filter: { fields: { localEvent: { eq: "true" } } }
      sort: { fields: fields___endDate, order: ASC }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          fields {
            id
            seoContent {
              menuTitle
              pageTitle
              slug
              description {
                description
              }
            }
            startDate
            endDate
          }
        }
      }
    }
    contentfulPages(id: { eq: "11bff901-0cf1-5350-b5fd-69b6d7950243" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        title
        subtitle
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default EventsPage;
